<template>
  <div>
    <div class="search-input w-100 d-flex align-content-center position-relative flex-wrap">
      <img class="position-absolute icon-search" src="@/assets/images/icon-search.svg" alt="" />
      <input type="text" :value="value" placeholder="請輸入隊伍ID" @input="$emit('input', $event.target.value)" />
    </div>
  </div>
</template>

<script>
export default {
  name: "searchInput",
  props: ["value"]
};
</script>

<style scoped lang="scss"></style>
