<template>
  <!-- <li class="form-row" @click="selectMember"> -->
  <li class="form-row">
    <div class="col-auto">
      <figure class="circle my-0 mr-2">
        <img class="cover-fit" v-bind:src="showAvatar(member.user.avatar)" v-bind:alt="member.user.nickname" />
      </figure>
      <div class="light-color">{{ member.character }}</div>
    </div>
    <div class="col text-truncate">
      {{ member.user.nickname }}
    </div>
    <div class="col-auto">
      <div class="small-font d-flex align-items-center" v-bind:class="memberClass(isReady)">
<!--        <div class="member-status">{{ memberText() }}</div>-->
        <!-- <button v-show="isLeader && notSelf" class="btn-member text-nowrap" @click.stop="removeMember(member)">
          剔除
        </button> -->
      </div>
    </div>
  </li>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import TeamFromVuex from "@/Mixins/TeamFromVuex";
import UserFromVuex from "../../Mixins/UserFromVuex";

export default {
  name: "teamMember",
  mixins: [Avatar, TeamFromVuex, UserFromVuex],
  props: {
    member: {
      required: true,
      type: Object
    }
  },
  computed: {
    isLeader() {
      return this.team?.payload?.leader == this?.user?.id;
    },
    notSelf() {
      return this.member?.user?.id !== this?.user?.id;
    },
    isReady() {
      if (this.team_payload?.ready_list) {
        let key = this.member.user_id.toString();
        let ready = this.team_payload?.ready_list[key];
        return ready == "1";
      }
      return false;
    }
  },
  methods: {
    memberClass: function(prepare) {
      if (prepare == false) {
        return "light-color";
      }
      return "member-ready";
    },
    memberText: function() {
      if (!this.isReady) {
        return "尚未準備";
      }
      return "準備完成";
    },
    selectMember() {
      this.$emit("click", this.member);
    }
  }
};
</script>

<style scoped lang="scss">
li {
  user-select: none;
  &.selected {
    background: rgba(27, 167, 233, 0.5);
    .light-color {
      color: #818181;
    }
    .member-status {
      display: none;
    }
    .btn-member {
      display: block;
    }
  }
  .btn-member {
    display: none;
  }
}
</style>
