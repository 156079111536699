<template>
  <li class="form-row">
    <div class="col-auto">
      <figure class="circle my-0 mr-2">
        <img class="cover-fit" v-bind:src="showAvatar(member.user.avatar)" v-bind:alt="member.user.nickname" />
      </figure>
      <!--      <div class="light-color">{{ member.character }}</div>-->
    </div>
    <div class="col text-truncate">
      {{ member.user.nickname }}
    </div>
  </li>
</template>

<script>
import Avatar from "@/Mixins/Avatar";

export default {
  name: "otherTeamMember",
  mixins: [Avatar],
  props: {
    member: {
      required: true,
      type: Object
    }
  },

  methods: {
    memberClass: function(prepare) {
      if (prepare == 0) {
        return "light-color";
      }
      return "member-ready";
    },
    memberText: function(prepare) {
      if (prepare == 0) {
        return "準備中";
      }
      return "準備完成";
    }
  }
};
</script>

<style scoped lang="scss">
li {
  &.selected {
    background: rgba(255, 85, 126, 0.5);
    .light-color {
      color: #818181;
    }
    .member-status {
      display: none;
    }
    .btn-member {
      display: block;
    }
  }
  .btn-member {
    display: none;
    background: #ff6c8b;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 20px;
    padding: 2px 18px;
  }
}
</style>
