<template>
  <div class="overflow-hidden position-relative app-content">
    <div class="mask" v-show="mask" @click="nextHint"></div>
    <div
      v-if="false"
      class="hint-circle d-flex justify-content-center align-items-center position-absolute"
      @click="showHint"
    >
      <img src="@/assets/images/icon-help.svg" alt="" />
    </div>
    <div class="full-panel d-flex flex-column justify-content-between h-100">
      <div class="m-auto" style="width:110px">
        <userFigure :user="user" />
        <div class="text-center">{{ user.nickname }}</div>
        <div class="small-font text-center">
          隊伍 ID: <span v-if="team">{{ team.code || "" }}</span>
        </div>
      </div>
      <a
        class="pt-4 font-20 d-flex justify-content-center align-items-center cursor-pointer"
        role="button"
        @click="addOtherTeam"
        v-if="isLeader && !otherTeamInput"
      >
        <div class="circle s30 mr-2 text-white d-flex justify-content-center align-items-center bg-primary">
          +
        </div>
        <span class="text-primary">增加同遊隊伍</span>
      </a>
      <div
        class="team-input-wrap position-relative mt-4"
        v-if="isLeader && otherTeamInput"
        @blur="otherTeamInput = false"
      >
        <input type="text" v-model="other_team_code" class="team-input my-4" placeholder="輸入其他隊伍 ID" />
        <button class="btn btn-primary btn-block" @click="DoAddTeam">加入同遊隊伍</button>
      </div>
      <div class="team-card">
        <header class="position-relative">
          <div class="position-relative">
            <input
              type="text"
              class="input-no-border h4 mb-0 text-white w-100 team-name"
              v-model="teamName"
              maxlength="10"
              ref="teamName"
              :disabled="!isLeader"
              @input="teamNameChange"
              id="name"
              required
            />
            <label v-if="isLeader" class="fake-label" for="name">取個隊伍名稱（10字內）</label>
          </div>
          <a v-if="isLeader" class="position-absolute team-name-edit" @click="teamNameEdit">
            <svg class="btn-icon-16">
              <use xlink:href="#icon-edit" class="fill-white"></use>
            </svg>
          </a>
        </header>
        <ul class="list-unstyled team-members m-0">
          <teamMember :member="member" v-for="member in members" :key="member.id" v-bind="$attrs"> </teamMember>
        </ul>
        <!--        <div class="team-ready py-4 font-20 text-center" v-if="allReady">-->
        <!--          準備出發-->
        <!--        </div>-->
        <footer>
          <div class="form-row" v-if="isLeader">
            <!--            <div class="col">-->
            <!--              <a v-if="!allReady" id="doReady" href="#" class="btn btn-primary btn-block" @click="setReady">準備完成</a>-->
            <!--              <a v-else id="doReady" href="#" class="btn-cancel btn-block text-center" @click="setReady">取消準備</a>-->
            <!--            </div>-->
            <div class="col-auto" v-show="isLeader">
              <button @click="disband" type="button" class="btn btn-outline-primary">
                解散
              </button>
            </div>
          </div>
          <!--          <div class="form-row" v-else>-->
          <!--            <div class="col">-->
          <!--              <a v-if="!allReady" id="doReady" href="#" class="btn btn-primary btn-block" @click="setReady">準備完成</a>-->
          <!--              <a v-else id="doReady" href="#" class="btn-cancel btn-block text-center" @click="setReady">取消準備</a>-->
          <!--            </div>-->
          <!--          </div>-->
        </footer>
      </div>
      <div>
        <div class="form-group">
          <div class="form-row" v-if="isLeader">
            <div class="col">
              <a class="btn btn-primary btn-block text-white" @click="$router.replace('qrCodeLeader')">加入隊員</a>
            </div>
          </div>
          <div class="form-row" v-else>
            <div class="col">
              <a class="btn btn-block btn-outline-primary" @click="leaveTeam">離隊</a>
            </div>
          </div>
        </div>
        <button v-if="isLeader" class="btn btn-primary btn-block" :disabled="!allReady" @click="gameStart">
          開始遊戲
        </button>

        <div class="py-4" v-show="otherTeams">
          <h3 class="text-center mb-0" v-if="otherTeams.length !== 0">同遊隊伍</h3>
          <div class="team-card other-team-card m-2" v-for="team in otherTeams" :key="team.id">
            <header class="position-relative">
              <div>
                {{ team.title }}
              </div>
            </header>
            <ul class="list-unstyled team-members m-0">
              <otherTeamMember
                :member="member"
                v-for="member in team.member"
                :key="member.id"
                :class="{
                  selected: selected_member_id == member.id
                }"
                v-bind="$attrs"
              >
              </otherTeamMember>
            </ul>
            <!--            <div class="team-ready py-4 font-20 text-center" v-if="allReady">-->
            <!--              準備出發-->
            <!--            </div>-->
          </div>
        </div>
        <button class="btn btn-primary my-4 btn-block customService" @click="customService">
          聯絡客服（10:00-20:00）
          <img class="icon" src="/img/icons/line.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import Avatar from "@/Mixins/Avatar";

import teamMember from "@/views/part/teamMember";
import { sendMessage } from "../../utils/WebSocket";
import { mapActions, mapGetters } from "vuex";
import UserFigure from "../part/UserFigure";
import UserFromVuex from "../../Mixins/UserFromVuex";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import WebSocketMixin from "../../Mixins/WebSocketMixin";
import otherTeamMember from "../part/otherTeamMember";
import ModalMixin from "@/Mixins/ModalMixin";
import HowlAudio from "@/Mixins/HowlAudio";

export default {
  name: "teamCreate",
  mixins: [Avatar, UserFromVuex, TeamFromVuex, WebSocketMixin, ModalMixin, HowlAudio],
  components: {
    teamMember,
    otherTeamMember,
    UserFigure
  },
  computed: {
    ...mapGetters("Game", ["teams"]),
    ...mapGetters("Stage", ["flag"]),
    lock_path() {
      return this.flag("lock_path");
    },
    isLeader() {
      return this.team?.payload?.leader == this?.user?.id;
    },
    otherTeams() {
      let team_id = this.team?.id;
      return this.teams.filter(d => d.id != team_id);
    },
    allReady() {
      return this.flag("member_limit_over_1") && this.members?.length > 1;
    }
  },
  mounted() {
    if (this?.team && this?.character) {
      this.$router.replace("homeMap");
      return;
    }
    this.preset();
    if (!this.isLeader) {
      this.teamName = "取個隊伍名稱（10字內）";
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm?.team) {
        vm.$router.replace("teamStart");
      }
    });
  },
  data() {
    return {
      // allReady: false,
      other_team_code: "",
      teamSyncTimeout: null,
      mask: false,
      hint: {
        show: false,
        target: 0,
        message: ["使用隊伍ID與其它隊伍同遊，可看到其它隊伍的進行狀態"],
        msgLength: 1
      },
      teamName: "",
      selected_member_id: null,
      addTeam: true,
      otherTeamInput: false,
      ready: false
    };
  },
  watch: {
    lock_path(val, oldVal) {
      if (val !== oldVal) {
        if (val === "characters") {
          this.$router.replace("characters");
        }
      }
    },
    team_payload: {
      deep: true,
      handler(team_payload) {
        if (team_payload) {
          if (team_payload?.router) {
            this.$router.replace(team_payload.router);
            return;
          }
        }
      }
    },
    team: {
      deep: true,
      handler(team, oldTeam) {
        if (team?.title != oldTeam?.title) {
          if (this.isLeader) return;
          this.teamName = team?.title;
        }
      }
    }
  },
  methods: {
    ...mapActions("Team", ["teamLeave"]),
    ...mapActions("User", ["setOnline"]),
    preset() {
      // let fd = this.team_payload?.message?.fd;
      // if (fd) {
      //   this.setFd(fd);
      //   return;
      // }
      if (this.team?.title !== "隊伍名稱") {
        this.teamName = this.team?.title;
      }
      // setTimeout(this.preset, 100);
    },
    DoAddTeam() {
      const data = {
        method: "GameJoin",
        token: this.token,
        code: this.team.code,
        other_team_code: this.other_team_code
      };
      sendMessage(data);
      this.otherTeamInput = false;
      this.other_team_code = null;
    },
    gameStart() {
      if (this.flag("member_limit_over_1") && this.members.length <= 1) {
        this.showModal("要兩人以上才能開始遊戲歐");
        return;
      }
      if (!this.allMemberOnline) {
        this.showModal("請確認所有人都在線上才能開始遊戲");
        return;
      }
      if (confirm("開始遊戲後就不能增加隊友或同遊隊伍，確定要開始遊戲嗎？")) {
        const data = {
          method: "GameStart",
          token: this.token
        };
        sendMessage(data);
      }
    },
    teamNameChange() {
      if (!this.isLeader) return;
      if (this.teamName.length == 0) {
        this.teamName = "";
        return;
      }
      const data = {
        method: "TeamUpdate",
        token: this.token,
        team: {
          id: this.team.id,
          title: this.teamName
        }
      };
      sendMessage(data);
    },
    showHint: function() {
      this.mask = true;
      this.hint.show = true;
    },
    nextHint: function() {
      this.hint.target++;
      if (this.hint.target >= this.hint.msgLength) {
        this.mask = false;
        this.hint.show = false;
        this.hint.target = 0;
      }
    },
    teamNameEdit: function() {
      this.$refs.teamName.focus();
    },
    disband: function() {
      if (!this.allMemberOnline) {
        this.showModal("請確認所有人都在線上才能解散");
        return;
      }
      if (this.otherTeams && this.otherTeams.length >= 1) {
        //離開同遊
        const data = {
          token: this.token,
          code: this.team?.code,
          method: "GameLeave"
        };
        sendMessage(data);
      }

      const data = {
        token: this.token,
        method: "TeamDisband"
      };
      sendMessage(data);
    },
    selectMember(member) {
      if (this.selected_member_id == member.id) {
        this.selected_member_id = null;
        return;
      }
      this.selected_member_id = member.id;
    },
    addOtherTeam() {
      this.otherTeamInput = true;
    },
    setReady() {
      return debounce(() => {
        // this.setOnline();
        this.ready = !this.ready;
        const data = {
          token: this.token,
          method: "TeamMemberReady",
          ready: this.ready
        };
        sendMessage(data);
      }, 200)();
    },
    goCharacters() {
      var audio = this.makeAudio("audio/teamBuild.mp3");
      audio.play();
      setTimeout(() => {
        this.$route.push("/characters");
      }, 5500);
    },
    leaveTeam() {
      const data = {
        token: this.token,
        method: "TeamLeave"
      };
      sendMessage(data);
    },
    customService() {
      // window.open("https://line.me/R/oaMessage/@489zbckq?message");
      window.open("https://lin.ee/WYQgbHE");
    }
  }
};
</script>

<style scoped lang="scss">
.team-card {
  label {
    font-weight: bold;
    color: #fff;
    position: absolute;
    left: 0;
    font-size: 1.3rem;
  }
  input[required]:invalid + label {
    display: inline-block;
  }
  input[required]:valid + label {
    display: none;
  }
}

.team-name-edit {
  right: 10px;
  top: 16px;
}

.hint-circle {
  right: 30px;
  top: 30px;

  img {
    width: 40px;
  }
}

.btn-cancel {
  padding: 12px;
  font-size: 20px;
  border-radius: 30px;
  border: none;
  text-decoration: none;
  color: #818181;
  background: #d5d5d5;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.3);
}
.customService {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #1ba7e9;
  img {
    width: 24px;
    height: 24px;
  }
}
</style>
