<template>
  <div class="position-relative user-figure-outer">
    <div class="position-absolute user-figure-edit d-flex justify-content-center align-items-center">
      <img src="/img/icons/figure-edit.png" alt="" @click="$refs.file.click()" />
      <input type="file" name="upload" ref="file" class="d-none" @change="uploadImg($event)" />
    </div>
    <figure class="circle position-relative mx-auto user-figure">
      <img v-show="!isUploading && userAvatar" class="cover-fit" :src="userAvatar" />
      <img v-if="isUploading" class="cover-fit" src="/img/loading.svg" alt="" />
    </figure>
  </div>
</template>
<script>
import Avatar from "../../Mixins/Avatar";
import { avatarUpload } from "../../utils/Api";
import ModalMixin from "../../Mixins/ModalMixin";
import { mapActions, mapGetters } from "vuex";
import EXIF from "@/Mixins/exif-js";

class UserUploadFail {
  constructor(message) {
    this.message = message;
    this.name = "UserUploadFail";
  }
}

export default {
  name: "userFigure",
  props: ["user"],
  mixins: [Avatar, ModalMixin],
  computed: {
    ...mapGetters("User", {
      vuex_user: "user"
    }),
    userAvatar() {
      if (this.image) {
        return this.image;
      }
      if (this?.user?.avatar) {
        return this.showAvatar(this.user.avatar);
      }
      return false;
    }
  },
  data() {
    return {
      image: null,
      uploadResult: null,
      isUploading: false
    };
  },
  methods: {
    ...mapActions("User", ["setUser"]),
    uploadImg(e) {
      //上传图片
      // this.option.img

      var file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("圖片類型必須為 gif,jpeg,jpg,png,bmp");
        return false;
      }
      // this.upload(file);
      var reader = new FileReader();
      var orientation = 0;
      EXIF.getData(file, function() {
        //取得照片拍攝的角度
        orientation = EXIF.getTag(this, "Orientation");
      });

      reader.onload = e => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        var rotateAngle = 0;
        switch (orientation) {
          case 3:
            rotateAngle = 180;
            break;
          case 6:
            rotateAngle = 90;
            break;
          case 8:
            rotateAngle = -90;
            break;
          default:
            rotateAngle = 0;
            break;
        }
        console.log("orientation: ", orientation);
        console.log("file.size: ", file.size);

        this.blobCompress(new Blob([e.target.result]), rotateAngle).then(file => {
          console.log("new file", file);
          this.avatarUpload(file);
        });

        //this.image = data;
        this.$refs.file.value = "";
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file);
    },

    avatarUpload(data) {
      this.setUploading(true);
      var formData = new FormData();
      formData.append("avatar", data);
      avatarUpload(formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          this.uploadResult = response.data;
          this.image = this.uploadResult?.data;
          this.setUploading(false);

          this.updateUserAvatar(response.data);
        })
        .catch(() => {
          this.showModal("上傳失敗 請上傳10MB以下的png,jpb圖片");
          this.setUploading(false);
        });
    },
    //壓縮上傳圖片
    blobCompress(blob, rotateAngle) {
      return new Promise(resolve => {
        //準備canvas
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        canvas.width = 400;
        canvas.height = 400;

        //blob轉成DataURL
        let fr = new FileReader();
        fr.readAsDataURL(blob);
        fr.onload = function(e) {
          let data = {
            canvas: canvas,
            context: context,
            dataURL: e.target.result,
            rotateAngle: rotateAngle
          };
          resolve(data);
        };
      })
        .then(data => {
          // dataURL轉成img
          return new Promise(resolve => {
            let img = new Image();
            img.src = data.dataURL;
            img.onload = function() {
              let imgWidth = img.width; //圖片寬度
              let imgHeight = img.height; //圖片高度
              let dWidth = data.canvas.width;
              let dHeight = data.canvas.height;
              let sx, sy, sWidth, sHeight; //裁切座標|裁切寬高

              //裁切照片置中，成為正方形
              let photo_Horizontal = imgWidth > imgHeight ? true : false;
              if (imgWidth > imgHeight) {
                sx = (imgWidth - imgHeight) / 2;
                sy = 0;
                sWidth = imgHeight;
                sHeight = imgHeight;
              } else {
                sy = (imgHeight - imgWidth) / 2;
                sx = 0;
                sWidth = imgWidth;
                sHeight = imgWidth;
              }
              data.context.save();
              data.context.translate(200, 200); //讓旋轉軸心設定在中央
              //data.context.rotate((data.rotateAngle * Math.PI) / 180);
              data.context.drawImage(img, sx, sy, sWidth, sHeight, -200, -200, dWidth, dHeight);
              data.context.restore();
              //在canvas重新繪製較小大小的圖片
              //image參數是來源影像物件，(sx, sy)代表在來源影像中以(sx, sy)座標點作為切割的起始點，
              //sWidth和sHeight代表切割寬和高，(dx, dy)代表放到畫布上的座標點，dWidth和dHeight代表縮放影像至指定的寬和高.
              resolve(data);
            };
          });
        })
        .then(function(data) {
          // 圖片壓縮:canvas轉為blob
          let canvasToBlob = () => {
            return new Promise(resolve => {
              data.canvas.toBlob(
                blob => {
                  resolve(new File([blob], "new file"));
                },
                "image/jpeg",
                0.8
              );
            });
          };
          return canvasToBlob();
        });
    },
    updateUserAvatar(response_data) {
      if (response_data) {
        if (response_data?.status !== "success") {
          throw new UserUploadFail("upload fail");
        }
        let user = {};
        Object.assign(user, this.vuex_user);
        user.avatar = response_data.data;
        this.setUser({ data: { user: user } });
      }
    },
    setUploading(status) {
      this.isUploading = status;
      this.$emit("setUploading", status);
    }
  }
};
</script>

<style lang="scss" scoped>
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 10px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
