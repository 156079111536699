<template>
  <div class="overflow-hidden position-relative app-content">
    <div class="mask mask-dark" v-show="enlarge"></div>
    <qrcode-stream @decode="onDecode"></qrcode-stream>
    <a @click="$router.replace('teamStart')" class="position-absolute back-icon ">
      <svg class="btn-icon">
        <use xlink:href="#arrow-left" class="fill-primary"></use>
      </svg>
    </a>

    <div class="qr-square position-absolute">
      <img src="@/assets/images/qr-code-border.svg" alt="" />
    </div>
    <div
      class="operate-area d-flex justify-content-center position-fixed text-center position-relative"
      v-bind:class="{ active: enlarge }"
    >
      <div class="back-area position-absolute text-right p-3" v-show="enlarge">
        <button class="btn btn-sm btn-outline-primary" @click="back">返回</button>
      </div>
      <div class="" v-show="!enlarge">
        <div class="mb-2 text-center">掃描隊長的 QR Code 即可加入隊伍<br />不方便掃描？跟隊長索取隊伍 ID</div>
        <!-- <button class="btn-border operate-btn mb-3 mx-auto" @click="showQrcode">顯示行動條碼</button> -->
        <button class="btn-border operate-btn mb-3 mx-auto" @click="enterTeamID">輸入隊伍 ID</button>
      </div>
      <div v-show="enlarge" class="h-100 w-100 ">
        <div class="d-flex flex-column justify-content-between h-100 ">
          <div v-show="teamInupt">
            <searchInput v-model="searchText"></searchInput>
            <div class="mt-4">
              <a role="button" class="btn btn-primary btn-block text-white" @click="doSearch">加入隊伍</a>
            </div>
          </div>
          <div v-show="qrCode" class="text-center">
            <img src="@/assets/images/qrcode.png" class="img-fluid" alt="" />
          </div>
          <div v-show="qrCode" class="font-20">掃描隊伍行動條碼後<br />即可加入隊伍</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//eslint-disable-next-line
  import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import searchInput from "@/views/part/searchInput";
import TeamFromVuex from "@/Mixins/TeamFromVuex";
import WebSocketMixin from "@/Mixins/WebSocketMixin";
import debounce from  'lodash.debounce';
// import Vue from "vue";

export default {
  name: "qrCode",
  mixins: [TeamFromVuex, WebSocketMixin],
  components: {
    QrcodeStream,
    // QrcodeDropZone,
    // QrcodeCapture,
    searchInput
  },
  data() {
    return {
      searchText: "",
      qrCode: false,
      enlarge: false,
      teamInupt: false
    };
  },
  mounted() {
    // this.initWebSocket();
  },
  methods: {
    onDecode : debounce(function(decodedString){
      this.searchText = decodedString;
      this.teamJoin(decodedString);
    },200),
    doSearch: debounce(function() {
      this.teamJoin(this.searchText);
    },200),
    showQrcode: function() {
      this.enlarge = true;
      this.qrCode = true;
    },
    enterTeamID: function() {
      this.enlarge = true;
      this.teamInupt = true;
    },
    back: function() {
      this.enlarge = false;
      this.teamInupt = false;
      this.qrCode = false;
    }
  }
};
</script>

<style scoped lang="scss">
.app-content {
  height: 70vh;
}
.operate-btn {
  width: 170px;
  text-align: center;
  display: block;
}

.qr-square {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  z-index: 10000;
  img {
    max-width: 100%;
  }
}

.operate-area {
  transition: 0.5s;
  height: 30vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: #fff;
  border-radius: 20px 20px 0 0;
  align-items: center;
  padding: 20px 40px;
  z-index: 10000;
  &.active {
    height: 70vh;
    padding: 100px 40px 40px;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10000;
  }
}
.back-area {
  left: 0;
  top: 0;
  right: 0;
  height: 40px;
}
</style>
