<template>
  <div class="overflow-hidden position-relative app-content">
    <div class="mask mask-dark" v-show="enlarge"></div>
    <!--    <qrcode-stream></qrcode-stream>-->
    <a
      @click="$router.replace('teamCreate')"
      class="position-absolute d-flex align-items-center justify-content-center back-icon toTop"
    >
      <svg class="btn-icon">
        <use xlink:href="#arrow-left" class="fill-primary"></use>
      </svg>
    </a>

    <div class="qr-square position-absolute">
      <img src="@/assets/images/qr-code-border.svg" alt="" />
    </div>
    <div
      class="operate-area d-flex justify-content-center position-fixed text-center position-relative"
      v-bind:class="{ active: enlarge }"
    >
      <div class="back-area position-absolute" @click="back"></div>
      <!--      <div class="" v-show="!enlarge">-->
      <!--        <div class="mb-3 font-20">隊員掃描隊伍行動條碼後<br />即可加入隊伍</div>-->
      <!--        <button class="btn-border operate-btn mb-3 mx-auto" @click="showQrcode">顯示行動條碼</button>-->
      <!--      </div>-->
      <div v-if="enlarge" class="h-100 w-100 ">
        <div class="d-flex flex-column justify-content-between h-100 ">
          <div v-show="qrCode" class="text-center">
            <VueQrcode :value="team.code" :options="{ width: 300 }"></VueQrcode>
          </div>
          <div class="font-20" v-show="qrCode">
            隊伍ID : <spna>{{ team.code }}</spna>
          </div>
          <div class="font-20" v-show="qrCode">請隊員掃描QR Code後<br />即可加入你的隊伍</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//eslint-disable-next-line
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
//eslint-disable-next-line
import searchInput from "@/views/part/searchInput";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import WebSocketMixin from "../../Mixins/WebSocketMixin";
import TeamFromVuex from "../../Mixins/TeamFromVuex";

export default {
  name: "qrCodeLeader",
  mixins: [WebSocketMixin, TeamFromVuex],
  components: {
    // QrcodeStream,
    VueQrcode
    // QrcodeDropZone
    // QrcodeCapture
    // searchInput
  },
  data() {
    return {
      qrCode: true,
      enlarge: true
    };
  },
  methods: {
    showQrcode: function() {
      this.enlarge = true;
      this.qrCode = true;
    },
    back: function() {
      this.enlarge = false;
      this.qrCode = false;
    }
  }
};
</script>

<style scoped lang="scss">
.app-content {
  height: 70vh;
}
.operate-btn {
  width: 170px;
  text-align: center;
  display: block;
}

.qr-square {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  img {
    max-width: 100%;
  }
}

.operate-area {
  transition: 0.5s;
  height: 30vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: #fff;
  border-radius: 20px 20px 0 0;
  align-items: center;
  padding: 20px 40px;
  z-index: 10000;
  &.active {
    height: 70vh;
    padding: 100px 40px 40px;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10000;
  }
}
.back-area {
  left: 0;
  top: 0;
  right: 0;
  height: 40px;
}
.toTop {
  z-index: 10000;
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
}
</style>
