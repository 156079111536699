<template>
  <div class="position-relative dark-bg">
    <div class="info-content">
      <div class="d-flex">
        <a @click="$router.replace('homeMap')">
          <svg class="btn-icon mr-3">
            <use xlink:href="#icon-back" class="fill-primary"></use>
          </svg>
        </a>
        <h4 class="text-white">重新編隊</h4>
      </div>
      <div class="text-center small-font text-white my-2">
        離隊後無法再加入原本隊伍，遊戲進度也會中止。
      </div>
      <div class="team-card bg-white">
        <header class="position-relative">
          <div>
            {{ team.name }}
          </div>
        </header>
        <ul class="list-unstyled team-members m-0">
          <li
            class="form-row"
            v-for="(member, index) in members"
            :key="member.id"
            @click="selectMember(index)"
            :class="{ selected: member.leave }"
          >
            <div class="col-auto team-leader-check" :class="{ 'team-leader': member.leader }">
              <div class="check-square mr-2"></div>
              <div v-if="member.leader" class="text-primary">隊長</div>
            </div>
            <div class="col text-truncate">
              {{ member.nickname }}
            </div>
            <div class="col-auto">
              <div class="small-font d-flex align-items-center">
                <button class="btn-member text-nowrap" v-if="!member.leave">
                  繼續
                </button>
                <button class="btn-member text-nowrap btn-white" v-if="member.leave">
                  離隊
                </button>
              </div>
            </div>
          </li>
        </ul>
        <footer :class="{ 'bg-primary': setFinish }">
          <div v-show="setFinish">
            <div class="info-modal">
              <div class="text-white d-flex justify-content-between align-items-center mb-2">
                <div>確定更改為以上隊形？</div>
                <div class="modal-timer">{{ timer }} s</div>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="btn-outline-primary btn-block btn">
                    確定
                  </div>
                </div>
                <div class="col">
                  <div class="btn-primary btn-block btn">
                    取消變更
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col" v-show="!setFinish">
              <a class="btn btn-primary btn-block text-white" @click="save">設定完成</a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "teamReForm",
  data() {
    return {
      setFinish: false,
      timer: 20,
      team: {
        name: "test"
      },
      members: [
        {
          id: 11,
          character: "隊長",
          nickname: "老王",
          picture: "",
          prepare: 1,
          leader: true,
          leave: false
        },
        {
          id: 12,
          character: "隊員",
          nickname: "小二",
          picture: "",
          prepare: 1,
          leader: false,
          leave: true
        }
      ]
    };
  },
  methods: {
    save() {
      console.log("ok");
    },
    statusText: function(leave) {
      if (leave == 0) {
        return "離隊";
      }
      return "繼續";
    },
    selectMember(index) {
      console.log(index);
      this.members[index].leave = !this.members[index].leave;
      // this.$emit("click", this.member);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";

.dark-bg {
  min-height: 100vh;
}
li {
  &.selected {
    background: rgba(255, 85, 126, 0.5);
  }
  .team-leader-check {
    width: 100px;
    &.team-leader {
      .check-square {
        border: 1px solid $main-color;
        background: $main-color;
      }
    }
  }
  .check-square {
    width: 25px;
    height: 25px;
    border: 1px solid $gray1;
  }
  .btn-member {
    display: block;
    &.btn-white {
      border: none;
      background: #fff;
      color: $main-color;
    }
  }
}
.modal-timer {
  font-size: 24px;
  font-weight: bold;
}
</style>
