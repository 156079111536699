<template>
  <div class="full-page overflow-hidden position-relative app-content">
    <div class="full-panel d-flex flex-column justify-content-between h-100">
      <div class="d-flex h-100 align-items-center justify-content-center">
        <div>
          <userFigure :user="user" @setUploading="setUploading" />
          <div class="text-center">{{ user.nickname }}</div>
        </div>
      </div>
      <div>
        <div class="mb-3 text-center">隊長是決定關卡進行的重要角色<br />擔任隊長後不能中離也不能換人喔！</div>
        <div class="form-group">
          <a id="teamCreate" :disabled="uploading" @click="teamCreate" class="btn btn-primary btn-block text-white"
            >建立隊伍－成為隊長</a
          >
        </div>
        <div class="form-group">
          <a @click="$router.replace('qrCode')" :disabled="uploading" class="btn btn-primary btn-block text-white"
            >加入隊伍－擔任隊員</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";
import UserFromVuex from "@/Mixins/UserFromVuex";
import { mapActions } from "vuex";
import TeamFromVuex from "@/Mixins/TeamFromVuex";
import { sendMessage } from "../../utils/WebSocket";
import UserFigure from "../part/UserFigure";
import WebSocketMixin from "../../Mixins/WebSocketMixin";

export default {
  name: "teamStart",
  components: { UserFigure },
  mixins: [Avatar, UserFromVuex, TeamFromVuex, WebSocketMixin],
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm?.team) {
        vm.$router.replace("teamCreate");
      }
    });
  },
  data() {
    return {
      uploading: false
    };
  },
  mounted() {
    if (!this.user) {
      this.syncUser();
    }
    if (this?.team) {
      this.$router.replace("teamCreate");
    }
  },
  methods: {
    ...mapActions("Team", ["teamCreateOrUpdate"]),
    setUploading(status) {
      this.uploading = status;
    },
    syncUser() {
      const data = {
        method: "SetUser",
        token: this.token
      };
      sendMessage(data);
    },
    teamCreate() {
      const data = {
        method: "TeamCreateOrUpdate",
        token: this.token
      };
      sendMessage(data);
    }
  }
};
</script>
