<template>
  <div class="full-page overflow-hidden position-relative app-content">
    <div class="prePage shadow-sm" @click="goback">
      <a role="button">
        <div class="icon-up-size">
          <svg class="btn-icon">
            <use xlink:href="#arrow-left" class="fill-dark"></use>
          </svg>
        </div>
      </a>
    </div>
    <teamWating v-if="selectedCharacter && allReady == false"></teamWating>
    <link rel="preload" as="video" :href="getVideoPath('open')" />
    <a
      v-if="allReady && isLeader"
      id="gameStart"
      @click="start"
      class="position-fixed page-btn d-flex justify-content-center align-items-center"
    >
      開始遊戲
    </a>
    <a v-if="allReady && !isLeader" class="position-fixed page-btn d-flex justify-content-center align-items-center">
      等待隊長開始遊戲
    </a>
    <div class="full-panel position-relative">
      <h1 class="full-page-title-s text-center">登場人物介紹</h1>
    </div>
    <div class="character-area position-absolute">
      <div class="watching position-absolute">
        <ul class="list-unstyled">
          <li class="circle" v-for="member in watchingMembers" :key="member.id">
            <img :src="getAvatar(member)" alt="" />
          </li>
        </ul>
      </div>
      <a
        class="cha-arrow-btn cha-arrow-btn-l position-absolute"
        @click="changeCharacterIndex(-1)"
        v-show="characterIndex != 0"
      >
        <svg class="btn-icon">
          <use xlink:href="#arrow-left" class="fill-dark"></use>
        </svg>
      </a>
      <a
        id="nextCharacter"
        class="cha-arrow-btn cha-arrow-btn-r position-absolute"
        @click="changeCharacterIndex(+1)"
        v-show="characterIndex < roles.length - 1"
      >
        <svg class="btn-icon">
          <use xlink:href="#arrow-right" class="fill-dark"></use>
        </svg>
      </a>
      <ul
        class="list-unstyled character-list d-flex justify-content-center position-absolute m-0"
        :style="{ left: slideMove + 'px' }"
        v-touch:swipe.right="
          () => {
            this.changeCharacterIndex(-1);
          }
        "
        v-touch:swipe.left="
          () => {
            this.changeCharacterIndex(+1);
          }
        "
      >
        <li
          class="position-relative"
          v-for="(character, index) in roles"
          :key="character.name"
          :class="{
            active: index == characterIndex,
            imgL: index === characterIndex - 1,
            imgR: index === characterIndex + 1
          }"
        >
          <div class="cha-name position-absolute text-center">
            {{ character.name }}
          </div>
          <figure class="m-0">
            <img class="" :src="'/img/' + character.figure" alt="" />
          </figure>
        </li>
      </ul>
    </div>
    <div class="character-intro position-absolute">
      <div class="character-act position-relative d-flex justify-content-center">
        <template v-if="!displayCharacter.lock">
          <button
            v-if="!selectedByWho"
            id="selectCharacter"
            class="btn btn-primary select-btn"
            v-touch:tap="() => this.selectRole()"
          >
            扮演這個角色
          </button>
          <button v-else class="btn btn-primary select-btn position-relative">
            已被選
            <div class="position-absolute selected-user">{{ selectedByWho.nickname }}</div>
          </button>
          <button
            v-if="selectedByMe"
            class="btn btn-outline-primary position-absolute cha-btn-cancel"
            v-touch:tap="() => unSelectedRole()"
          >
            取消
          </button>
        </template>
      </div>
      <div class="character-content">
        <skill :skill="roles[characterIndex].ability" v-if="roles[characterIndex].ability"></skill>
        <div v-html="roles[characterIndex].intro"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { sendMessage } from "../../utils/WebSocket";
import UserFromVuex from "../../Mixins/UserFromVuex";
import CharacterFromVuex from "../../Mixins/CharacterFromVuex";
import ModalMixin from "../../Mixins/ModalMixin";
import TeamFromVuex from "../../Mixins/TeamFromVuex";
import WebSocketMixin from "../../Mixins/WebSocketMixin";
import { mapGetters } from "vuex";
import skill from "@/views/part/skill";
import teamWating from "@/views/part/teamWating";
import Avatar from "@/Mixins/Avatar";

export default {
  name: "characters",
  mixins: [Avatar, CharacterFromVuex, ModalMixin, UserFromVuex, TeamFromVuex, WebSocketMixin],
  components: {
    skill,
    teamWating
  },
  data() {
    return {
      windowWidth: 0,
      characterIndex: 0,
      role_select_by: []
    };
  },
  created() {
    this.windowWidth = window.innerWidth;
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "teamCreate" && !to.params.force) {
      next(false);
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters("Team", ["isLeader"]),
    ...mapGetters("Video", ["getVideoPath"]),
    allReady() {
      let userSelected = this?.team_payload?.user_selected;
      if (userSelected) {
        let keys = Object.keys(userSelected);
        if (keys.length != this.members.length) return false;
        for (let key of keys) {
          if (!Number.isSafeInteger(userSelected[key])) return false;
        }
        return true;
      }
      return false;
    },
    selectedByMe() {
      return this.selectedByWho && this.selectedByWho.id == this.user.id;
    },
    displayCharacter() {
      return this.roles[this.characterIndex];
    },
    slideMove() {
      return 0 - this.characterIndex * (this.windowWidth - 140) + 70;
    },
    selectedCharacter() {
      let userSelected = this?.team_payload?.user_selected;
      if (userSelected) {
        let character_id = userSelected[this.user.id.toString()] || userSelected[this.user.id.toString()] === 0 || null;
        if (character_id === null) return null;

        return character_id;
      }

      return null;
    },
    selectedByWho() {
      let userSelected = this?.team_payload?.user_selected;
      if (userSelected) {
        let key = Object.keys(userSelected).find(key => userSelected[key] === this.characterIndex);
        if (key) {
          return this.members.find(d => d.user_id == key)?.user;
        }
      }

      return null;
    },
    watchingMembers() {
      let user_list = [];
      let userWatching = this?.team_payload?.user_watching;
      if (userWatching) {
        for (let user_id in userWatching) {
          if (userWatching[user_id] == this.characterIndex) {
            user_list.push(user_id);
          }
        }
      }

      return this.members.filter(d => user_list.includes(d.user_id.toString()));
    }
  },
  mounted() {
    this.characterWatch(0);
  },
  methods: {
    goback() {
      if (this.isLeader) {
        const data = {
          method: "CharacterSelectCancel",
          token: this.token
        };
        sendMessage(data);
        return;
      }
      this.showModal("只有隊長可以按");
    },
    getAvatar(row) {
      let avatar = row?.user?.avatar ?? "";
      if (avatar.includes("default")) {
        avatar = row?.character?.picture;
      }
      return this.showAvatar(avatar || row?.character?.picture || row?.picture || row?.avatar);
    },
    changeCharacterIndex(step) {
      let new_index = this.characterIndex + step;

      if (new_index < 0) {
        return;
      }
      if (new_index >= this.roles.length) {
        return;
      }
      this.characterIndex = new_index;

      this.characterWatch(new_index);
    },
    characterSelect(selected = false) {
      const data = {
        method: "CharacterSelect",
        token: this.token,
        character: this.characterIndex,
        selected
      };
      sendMessage(data);
    },
    characterWatch(character = null, selected = false) {
      const data = {
        method: "CharacterWatch",
        token: this.token,
        character,
        selected
      };
      sendMessage(data);
    },
    selectRole() {
      this.characterSelect(true);
    },
    unSelectedRole() {
      this.characterSelect(false);
    },
    head(content) {
      return content.substring(0, 2);
    },
    start() {
      if (!this.allMemberOnline) {
        this.showModal("請確認所有人在線上 才能開始遊戲歐");
        return;
      }
      if (confirm("遊戲要開始囉？")) {
        // this.$router.replace('/homeMap')
        const data = {
          method: "CharacterSelectDone",
          token: this.token
        };
        sendMessage(data);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";
.prePage {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 25px;
  left: 18px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  .icon-up-size {
    width: 24px;
    height: 24px;
  }
}
.full-panel {
  z-index: 10;
}
.hint-text {
  top: calc(50% - 50px);
  bottom: auto;
}

.character-area {
  left: 0;
  top: 110px;
  right: 0;
  height: 56vh;
}

.character-list {
  top: 20px;
  transition: 0.3s;
}

.character-list li {
  width: calc(100vw - 140px);
  flex-shrink: 0;
  z-index: 2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
    filter: brightness(50%);
  }

  &.active {
    img {
      transform: scale(1.2);
      filter: brightness(100%);
    }
  }
  &.imgL {
    img {
      transform: translateX(50px);
    }
  }
  &.imgR {
    img {
      transform: translateX(-50px);
    }
  }
}

.character-intro {
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
}

.character-content {
  background: #fff;
  z-index: 5;
  height: 40vh;
  padding: 20px 70px;
  text-align: justify;
}

.character-act {
  padding-top: 20px;
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  .selected-user {
    right: -5px;
    top: -20px;
    background: #fff;
    border-radius: 20px;
    color: $main-color;
    padding: 0 20px;
    border: 2px solid $main-color;
  }
}

.select-btn {
  padding-left: 50px;
  padding-right: 50px;
}

.cha-arrow-btn {
  top: calc(50% - 60px);
  z-index: 100;
}

.cha-arrow-btn-l {
  left: 30px;
}

.cha-arrow-btn-r {
  right: 30px;
}

.cha-name {
  font-size: 23px;
  font-weight: bold;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.cha-btn-cancel {
  right: 30px;
  top: 20px;
}

.watching {
  top: 30vh;
  left: 30px;
  z-index: 100;
  li {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    background: #fff;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    left: 40px;
    @include default-btn-shadow;
    @for $i from 0 through 6 {
      &:nth-child(#{$i}) {
        bottom: $i * 20px + 30px;
      }
    }
  }
  span {
    display: block;
    width: 20px;
    height: 20px;
    overflow: hidden;
  }
}

svg {
  border-radius: 50%;
  background: white;
}
</style>
